import { FC, useEffect, useState } from 'react';

import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import {
  AbiRegistry,
  Address,
  AddressValue,
  ContractFunction,
  Field,
  ResultsParser,
  SmartContract,
  SmartContractAbi
} from '@elrondnetwork/erdjs';
import { ProxyNetworkProvider } from '@elrondnetwork/erdjs-network-providers';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import governanceAbi from 'abi/governance.abi.json';
import { network } from 'config';
import styles from './styles.module.scss';

const networkProvider = new ProxyNetworkProvider(network.gatewayAddress);
const abiRegistry = AbiRegistry.create(governanceAbi);
const abi = new SmartContractAbi(abiRegistry);
const contract = new SmartContract({
  address: new Address(network.governanceContract),
  abi: abi
});

// TODO: dedupe code
const getDaoStatus = async (daoAddress: Address, caller: Address) => {
  const getDAOStatus = contract.createQuery({
    func: new ContractFunction('DAOStatus'),
    args: [new AddressValue(daoAddress)],
    caller: caller
  });
  const daoResponse = await networkProvider.queryContract(getDAOStatus);
  const result: any = new ResultsParser().parseQueryResponse(
    daoResponse,
    contract.getEndpoint('DAOStatus')
  );

  // TODO: handle error
  return result.firstValue || result.returnMessage;
};

const MyDAOs: FC = () => {
  const { address } = useGetAccountInfo();
  const owner = new Address(address);
  const [loading, setLoading] = useState<boolean>(true);
  const [daos, setDaos] = useState<
    { address: Address; details: any; status: any }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      // TODO: convert to getDeployedSC(owner, pool_type) so we don't need to get ALL DAOs
      const getAllDAOs = contract.createQuery({
        func: new ContractFunction('getAllDAO'),
        args: [],
        caller: owner
      });
      const daoResponse = await networkProvider.queryContract(getAllDAOs);
      const result: any = new ResultsParser().parseQueryResponse(
        daoResponse,
        contract.getEndpoint('getAllDAO')
      );
      const mappedResults = result.values[0].items.map(
        (item: { value: Address }) => item.value
      );
      const daosWithDetail = mappedResults.map(async (daoAddress: Address) => {
        const getDetails = contract.createQuery({
          func: new ContractFunction('getVerifiedSC'),
          args: [new AddressValue(daoAddress)],
          caller: owner
        });
        const resp = await networkProvider.queryContract(getDetails);
        const verified: any = new ResultsParser().parseQueryResponse(
          resp,
          contract.getEndpoint('getVerifiedSC')
        );
        const details = [...verified.firstValue.fieldsByName].map(
          ([key, value]) => (
            <div key={key} style={{ color: 'white' }}>
              {key}: {value.value?.value?.toString() || value.value?.toString()}
            </div>
          )
        );
        return verified.firstValue.fields.some(
          (f: any) => f.name === 'owner' && owner.equals(f.value?.value)
        )
          ? [
              {
                address: daoAddress,
                details: details,
                status: (await getDaoStatus(daoAddress, owner))?.name
              }
            ]
          : [];
      });
      const daos = await Promise.all(daosWithDetail);
      setDaos(daos.flatMap((d) => d));
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  const handleRedirect = () =>
    Boolean(address) ? setLoading(false) : navigate('/unlock');

  useEffect(handleRedirect, [address]);

  if (loading) {
    return (
      <div
        style={{ fontSize: '30px' }}
        className='d-flex align-items-center justify-content-center text-white flex-fill'
      >
        <FontAwesomeIcon
          icon={faSpinner}
          size='2x'
          spin={true}
          className='mr-3'
        />
        Loading...
      </div>
    );
  }

  return (
    <div className={styles.myDaos}>
      <h2>My DAOs</h2>
      {daos?.map((d) => (
        <>
          <h3>{d.address.bech32()}</h3>
          <h3>Status: {d.status}</h3>
          {d.details}
        </>
      ))}
    </div>
  );
};

export default MyDAOs;
