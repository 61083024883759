import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks/account';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import { SendTransactionReturnType } from '@elrondnetwork/dapp-core/types';
import { getChainID, parseAmount } from '@elrondnetwork/dapp-core/utils';
import {
  Address,
  ContractFunction,
  BytesValue,
  TransactionPayload,
  Transaction,
  BigUIntValue,
  U64Value
} from '@elrondnetwork/erdjs';
import BigNumber from 'bignumber.js';

import { network } from 'config';
import { DeployVotingSystemPayload, VerifyDAOPayload } from 'config.types';

const useDAOTransactions = () => {
  const { account } = useGetAccountInfo();

  const sendVerify = async (
    params: VerifyDAOPayload
  ): Promise<SendTransactionReturnType> => {
    const addr = new Address(network.governanceContract);
    const data = TransactionPayload.contractCall()
      .setFunction(new ContractFunction('verify'))
      .setArgs([
        BytesValue.fromUTF8(params.daoName),
        BytesValue.fromUTF8(params.projectName),
        BytesValue.fromUTF8(params.logo),
        BytesValue.fromUTF8(params.description),
        BytesValue.fromUTF8(params.website),
        BytesValue.fromUTF8(params.github),
        BytesValue.fromUTF8(params.twitter),
        BytesValue.fromUTF8(params.facebook)
      ])
      .build();

    const transaction = new Transaction({
      data,
      chainID: getChainID().valueOf(),
      receiver: addr,
      value: 0,
      gasLimit: 60000000,
      sender: new Address(account?.address)
    });

    return await sendTransactions({
      transactions: transaction
    });
  };

  const sendDeployVotingSystem = async (
    payload: DeployVotingSystemPayload
  ): Promise<SendTransactionReturnType> => {
    const data = TransactionPayload.contractCall()
      .setFunction(new ContractFunction('ESDTTransfer'))
      .setArgs([
        BytesValue.fromUTF8(network.votingSystemPaymentToken),
        new BigUIntValue(parseAmount(network.votingSystemPaymentPrice)),
        BytesValue.fromUTF8('deployVotingSystem'),
        new U64Value(new BigNumber(payload.seconds)),
        BytesValue.fromUTF8(payload.tokenId),
        new BigUIntValue(parseAmount(payload.costVote)),
        BytesValue.fromUTF8(payload.daoType)
      ])
      .build();

    const transaction = new Transaction({
      data,
      chainID: getChainID().valueOf(),
      receiver: new Address(network.governanceContract),
      value: 0,
      gasLimit: 60000000,
      sender: new Address(account?.address)
    });

    return await sendTransactions({
      transactions: transaction
    });
  };

  return {
    sendVerify,
    sendDeployVotingSystem
  };
};

export default useDAOTransactions;
