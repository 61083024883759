import React, { FC, useEffect, useState } from 'react';

import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';

import Cards from 'components/Cards';
import DAO from 'components/DAO';
import Voting from 'components/Voting';
import styles from './styles.module.scss';

const Dashboard: FC = () => {
  const { address } = useGetAccountInfo();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const handleRedirect = () =>
    Boolean(address) ? setLoading(false) : navigate('/unlock');

  useEffect(handleRedirect, [address]);

  if (loading) {
    return (
      <div
        style={{ fontSize: '30px' }}
        className='d-flex align-items-center justify-content-center text-white flex-fill'
      >
        <FontAwesomeIcon
          icon={faSpinner}
          size='2x'
          spin={true}
          className='mr-3'
        />
        Loading...
      </div>
    );
  }

  return (
    <div className={styles.dashboard}>
      <h2>Welcome to the Governance Portal!</h2>
      <DAO />
      <Link style={{ margin: '30px', display: 'block' }} to='/daos'>
        View All DAOs
      </Link>
      <Link style={{ margin: '30px', display: 'block' }} to='/my-daos'>
        My DAOs
      </Link>
      <Voting />
      <Cards />
    </div>
  );
};

export default Dashboard;
