import { FC, useEffect, ReactNode } from 'react';

import {
  ProxyNetworkProvider,
  ApiNetworkProvider
} from '@elrondnetwork/erdjs-network-providers';
import { faServer, faCog, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Logo from 'assets/Logo';

import Action from 'components/Action';
import { network, auctionContract } from 'config';
import { useGlobalContext, useDispatch } from 'context';

import modifiable from 'helpers/modifiable';

import styles from './styles.module.scss';

interface CardType {
  label: string;
  colors: Array<string>;
  data: {
    value?: string | null;
    percentage?: string | undefined;
  };
  title?: string;
  description?: string;
  modal?: ReactNode;
  icon: ReactNode;
}

const Cards: FC = () => {
  const { totalNetworkStake, networkStatus } = useGlobalContext();
  const dispatch = useDispatch();
  const location = useLocation();

  const getNetworkStatus = async (): Promise<void> => {
    dispatch({
      type: 'getNetworkStatus',
      networkStatus: {
        status: 'loading',
        data: null,
        error: null
      }
    });

    try {
      const [status, balance] = await Promise.all([
        new ProxyNetworkProvider(network.gatewayAddress).getNetworkStatus(),
        axios.get(`${network.apiAddress}/accounts/${auctionContract}`)
      ]);

      dispatch({
        type: 'getNetworkStatus',
        networkStatus: {
          status: 'loaded',
          error: null,
          data: {
            ...status,
            Balance: balance.data.balance
          }
        }
      });
    } catch (error) {
      dispatch({
        type: 'getNetworkStatus',
        networkStatus: {
          status: 'error',
          data: null,
          error
        }
      });
    }
  };

  const getTotalNetworkStake = async (): Promise<void> => {
    dispatch({
      type: 'getTotalNetworkStake',
      totalNetworkStake: {
        data: null,
        error: null,
        status: 'loading'
      }
    });

    try {
      const query = new ApiNetworkProvider(network.apiAddress, {
        timeout: 4000
      });

      const data = await query.getNetworkStatus();

      dispatch({
        type: 'getTotalNetworkStake',
        totalNetworkStake: {
          status: 'loaded',
          error: null,
          data
        }
      });
    } catch (error) {
      dispatch({
        type: 'getTotalNetworkStake',
        totalNetworkStake: {
          status: 'error',
          data: null,
          error
        }
      });
    }
  };

  const cards: Array<CardType> = [
    {
      label: 'Proposal 1',
      data: {
        value: 'Proposal to burn tokens in the amount of 1,000,000,000 RISA'
      },
      colors: ['#2044F5', '#1B37C0'],
      icon: <Logo />
    },
    // {
    //   label: 'Number of Users',
    //   colors: ['#6CADEF', '#5B96D2'],
    //   icon: <FontAwesomeIcon icon={faUsers} />,
    //   data: {
    //     value:
    //       usersNumber.status !== 'loaded'
    //         ? usersNumber.error
    //           ? 'Data Unavailable'
    //           : '...'
    //         : usersNumber.data
    //   }
    // },
    {
      label: 'Proposal 2',
      icon: <FontAwesomeIcon icon={faServer} />,
      colors: ['#36CA8C', '#2BA572'],
      data: { value: 'This proposal is to update staking duration on Odin.' }
    },
    {
      label: 'Proposal 3',
      colors: ['#FBC34C', '#D49712'],
      icon: <FontAwesomeIcon icon={faCheck} />,
      data: {
        value: 'Proposal for establishing a committee on DEFI incentives.'
      }
    }
    // {
    //   label: 'Service Fee',
    //   modal: <ChangeServiceFee />,
    //   icon: <FontAwesomeIcon icon={faReceipt} />,
    //   title: 'Change service fee',
    //   colors: ['#F3BF89', '#B68350'],
    //   data: {
    //     value: contractDetails.data
    //       ? contractDetails.data.serviceFee
    //       : contractDetails.error
    //       ? 'Service Fee Unknown'
    //       : '...%'
    //   }
    // },
    // {
    //   label: 'Delegation Cap',
    //   modal: <ChangeDelegationCap />,
    //   description: `The delegation cap is the maximum amount of ${network.egldLabel} your agency can stake from delegators.`,
    //   title: 'Delegation Cap',
    //   icon: <FontAwesomeIcon icon={faArrowUp} />,
    //   colors: ['#E48570', '#C25C45'],
    //   data: getDelegationCap()
    // }
  ];

  const fetchNetworkStatus = () => {
    if (!networkStatus.data) {
      getNetworkStatus();
    }
  };

  const fetchTotalNetworkStake = () => {
    if (!totalNetworkStake.data) {
      getTotalNetworkStake();
    }
  };

  useEffect(fetchNetworkStatus, [networkStatus.data]);
  useEffect(fetchTotalNetworkStake, [totalNetworkStake.data]);

  return (
    <>
      <div className={`${styles.cards} cards`}>
        {cards.map((card) => {
          const [alpha, beta] = card.colors;
          const background = `linear-gradient(180deg, ${alpha} 0%, ${beta} 100%)`;
          const interactive = card.modal && location.pathname === '/admin';

          return (
            <div
              key={card.label}
              className={styles.card}
              style={{ background }}
            >
              <div className={styles.heading}>
                <span>{card.label}</span>
                <div
                  style={{ fill: interactive ? beta : 'white' }}
                  className={modifiable(
                    'icon',
                    [interactive && 'fill'],
                    styles
                  )}
                >
                  {interactive ? (
                    <Action
                      render={card.modal}
                      title={card.title}
                      description={card.description}
                      trigger={
                        <span className={styles.trigger}>
                          <FontAwesomeIcon icon={faCog} size='lg' />
                        </span>
                      }
                    />
                  ) : (
                    card.icon
                  )}
                </div>
              </div>

              <div className={styles.value}>{card.data.value}</div>

              {card.data.percentage && <span>{card.data.percentage}</span>}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Cards;
