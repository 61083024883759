import { FC } from 'react';

import { Formik } from 'formik';
import { object } from 'yup';

import Action, { Submit } from 'components/Action';
import useDAOTransactions from 'components/DAO/hooks';

import modifiable from 'helpers/modifiable';

import { required } from '../../helpers/validators';
import styles from './styles.module.scss';

const VerifyDAO: FC = () => {
  const { sendVerify } = useDAOTransactions();

  return (
    <div className={`${styles.wrapper} delegate-wrapper`}>
      <Action
        title='DAO Profile'
        description={'Create a profile for your new DAO.'}
        trigger={<div className={styles.trigger}>DAO Profile</div>}
        render={
          <div className={styles.delegate}>
            <Formik
              validationSchema={object().shape({
                daoName: required()
              })}
              onSubmit={sendVerify}
              initialValues={{
                daoName: '',
                projectName: '',
                logo: '',
                description: '',
                website: '',
                github: '',
                twitter: '',
                facebook: ''
              }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={styles.field}>
                      <label htmlFor='daoName'>Name of DAO</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='daoName'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.daoName && touched.daoName && 'invalid'],
                            styles
                          )}
                          value={values.daoName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.daoName && touched.daoName && (
                        <span className={styles.error}>{errors.daoName}</span>
                      )}
                    </div>
                    <div className={styles.field}>
                      <label htmlFor='projectName'>Project Name</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='projectName'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [
                              errors.projectName &&
                                touched.projectName &&
                                'invalid'
                            ],
                            styles
                          )}
                          value={values.projectName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.projectName && touched.projectName && (
                        <span className={styles.error}>
                          {errors.projectName}
                        </span>
                      )}
                    </div>

                    <div className={styles.field}>
                      <label htmlFor='logo'>Logo</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='logo'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.logo && touched.logo && 'invalid'],
                            styles
                          )}
                          value={values.logo}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.logo && touched.logo && (
                        <span className={styles.error}>{errors.logo}</span>
                      )}
                    </div>

                    <div className={styles.field}>
                      <label htmlFor='description'>Description</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='description'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [
                              errors.description &&
                                touched.description &&
                                'invalid'
                            ],
                            styles
                          )}
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.description && touched.description && (
                        <span className={styles.error}>
                          {errors.description}
                        </span>
                      )}
                    </div>

                    <div className={styles.field}>
                      <label htmlFor='website'>Website</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='website'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.website && touched.website && 'invalid'],
                            styles
                          )}
                          value={values.website}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.website && touched.website && (
                        <span className={styles.error}>{errors.website}</span>
                      )}
                    </div>

                    <div className={styles.field}>
                      <label htmlFor='github'>Github</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='github'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.github && touched.github && 'invalid'],
                            styles
                          )}
                          value={values.github}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.github && touched.github && (
                        <span className={styles.error}>{errors.github}</span>
                      )}
                    </div>

                    <div className={styles.field}>
                      <label htmlFor='twitter'>Twitter</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='twitter'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.twitter && touched.twitter && 'invalid'],
                            styles
                          )}
                          value={values.twitter}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.twitter && touched.twitter && (
                        <span className={styles.error}>{errors.twitter}</span>
                      )}
                    </div>

                    <div className={styles.field}>
                      <label htmlFor='facebook'>facebook</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='facebook'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.facebook && touched.facebook && 'invalid'],
                            styles
                          )}
                          value={values.facebook}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.facebook && touched.facebook && (
                        <span className={styles.error}>{errors.facebook}</span>
                      )}
                    </div>
                    <Submit save='Continue' />
                  </form>
                );
              }}
            </Formik>
          </div>
        }
      />
    </div>
  );
};

export default VerifyDAO;
