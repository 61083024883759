import { FC } from 'react';

import { Formik } from 'formik';
import { object } from 'yup';

import Action, { Submit } from 'components/Action';
import { network } from 'config';

import modifiable from 'helpers/modifiable';

import styles from './styles.module.scss';

const Delegate: FC = () => {
  return (
    <div className={`${styles.wrapper} delegate-wrapper`}>
      <Action
        title='Vote Now'
        description={
          'Proposal #1 - A sample proposal for testing the voting system..'
        }
        trigger={<div className={styles.trigger}>Vote</div>}
        render={
          <div className={styles.delegate}>
            <Formik
              validationSchema={object().shape({
                // amount: delegateValidator(balance, limit)
              })}
              onSubmit={() => {
                null;
              }}
              initialValues={{
                amount: '1'
              }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={styles.field}>
                      <label htmlFor='amount'>{network.egldLabel} Amount</label>
                      <div className={styles.group}>
                        <input
                          type='number'
                          name='amount'
                          step='any'
                          required={true}
                          autoComplete='off'
                          min={1}
                          className={modifiable(
                            'input',
                            [errors.amount && touched.amount && 'invalid'],
                            styles
                          )}
                          value={values.amount}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={false}
                        />

                        <a
                          href='/#'
                          className={modifiable('max', ['disabled'], styles)}
                        >
                          Max
                        </a>
                      </div>
                      {errors.amount && touched.amount && (
                        <span className={styles.error}>{errors.amount}</span>
                      )}
                    </div>

                    <Submit save='Continue' />
                  </form>
                );
              }}
            </Formik>
          </div>
        }
      />
    </div>
  );
};

export default Delegate;
