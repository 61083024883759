import { FC, ReactNode, MouseEvent } from 'react';

import Logo from 'assets/Logo';
import { network } from 'config';
import { useGlobalContext } from 'context';
import modifiable from 'helpers/modifiable';

import Delegate from './components/Delegate';

import styles from './styles.module.scss';

interface ActionType {
  label: string;
  render?: ReactNode;
  transaction?: (value: MouseEvent) => Promise<void>;
}

interface PanelType {
  subicon: ReactNode;
  color: string;
  title: string;
  value: string;
  disabled: boolean;
  actions: Array<ActionType>;
}

const Voting: FC = () => {
  const { userActiveStake, userClaimableRewards } = useGlobalContext();
  const { isLoading, isEmpty, isError } = {
    isEmpty: userActiveStake.data === '0' && userClaimableRewards.data === '0',
    isLoading:
      userActiveStake.status === 'loading' ||
      userClaimableRewards.status === 'loading',
    isError:
      userActiveStake.status === 'error' ||
      userClaimableRewards.status === 'error'
  };

  const panels: Array<PanelType> = [];

  return (
    <div
      className={`${modifiable(
        'stake',
        [(isLoading || isError || isEmpty) && 'empty'],
        styles
      )} stake`}
    >
      {isLoading || isError || isEmpty ? (
        <div className={styles.wrapper}>
          <div className={styles.message}>
            {isLoading
              ? 'Retrieving voting data...'
              : isError
              ? 'You have no recent votes.'
              : 'Currently there are no active votes.'}
          </div>

          <Delegate />
        </div>
      ) : (
        panels.map((panel, index) => (
          <div key={panel.title} className={styles.panel}>
            <div
              className={modifiable('icon', [index > 0 && 'inversed'], styles)}
            >
              <Logo />

              {index > 0 &&
                Array.from({ length: 4 }).map((item, iteratee) => (
                  <strong
                    key={`plus-${iteratee}`}
                    className={modifiable('plus', [iteratee + 1], styles)}
                  >
                    +
                  </strong>
                ))}

              <div
                style={{ background: panel.color }}
                className={styles.subicon}
              >
                {panel.subicon}
              </div>
            </div>

            <div className={styles.title}>{panel.title}</div>

            <strong className={styles.value}>
              {panel.value} {network.egldLabel}
            </strong>

            <div className={styles.actions}>
              {panel.actions.map((action, iteratee) =>
                action.render ? (
                  <div key={action.label}>{action.render}</div>
                ) : (
                  <button
                    key={action.label}
                    type='button'
                    style={{ background: iteratee ? panel.color : '#303234' }}
                    className={modifiable(
                      'action',
                      [panel.disabled && 'disabled'],
                      styles
                    )}
                    onClick={action.transaction}
                  >
                    {action.label}
                  </button>
                )
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Voting;
