import { FC } from 'react';

import { Formik } from 'formik';
import { object } from 'yup';

import Action, { Submit } from 'components/Action';
import { deployDAOValidator } from 'components/DAO/helpers/deployDAOValidators';
import useDAOTransactions from 'components/DAO/hooks';
import modifiable from 'helpers/modifiable';

import styles from './styles.module.scss';

const DeployDAO: FC = () => {
  const { sendDeployVotingSystem } = useDAOTransactions();

  return (
    <div className={`${styles.wrapper} delegate-wrapper`}>
      <Action
        title='New DAO'
        description={'It will cost 5 DSUPER-9af8df to create a new DAO.'}
        trigger={<div className={styles.trigger}>New DAO</div>}
        render={
          <div className={styles.delegate}>
            <Formik
              validationSchema={object().shape({
                seconds: deployDAOValidator()
              })}
              onSubmit={sendDeployVotingSystem}
              initialValues={{
                seconds: '',
                tokenId: '',
                costVote: '0',
                daoType: 'dao'
              }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={styles.field}>
                      <label htmlFor='seconds'>
                        Proposal Duration (in seconds)
                      </label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='seconds'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.seconds && touched.seconds && 'invalid'],
                            styles
                          )}
                          value={values.seconds}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.seconds && touched.seconds && (
                        <span className={styles.error}>{errors.seconds}</span>
                      )}

                      <label htmlFor='tokenId'>Token ID</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='tokenId'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.tokenId && touched.tokenId && 'invalid'],
                            styles
                          )}
                          value={values.tokenId}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.tokenId && touched.tokenId && (
                        <span className={styles.error}>{errors.tokenId}</span>
                      )}

                      <label htmlFor='costVote'>Cost to Vote</label>
                      <div className={styles.group}>
                        <input
                          type='text'
                          name='costVote'
                          step='any'
                          required={true}
                          autoComplete='off'
                          className={modifiable(
                            'input',
                            [errors.costVote && touched.costVote && 'invalid'],
                            styles
                          )}
                          value={values.costVote}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>

                      {errors.costVote && touched.costVote && (
                        <span className={styles.error}>{errors.costVote}</span>
                      )}
                    </div>

                    <Submit save='OK' />
                  </form>
                );
              }}
            </Formik>
          </div>
        }
      />
    </div>
  );
};

export default DeployDAO;
