import { FC, ReactNode, MouseEvent } from 'react';

import { formatAmount } from '@elrondnetwork/dapp-core/utils';

import { useGlobalContext } from 'context';
import modifiable from 'helpers/modifiable';

import DeployDAO from './components/DeployDAO';
import VerifyDAO from './components/VerifyDAO';

import styles from './styles.module.scss';

interface ActionType {
  label: string;
  render?: ReactNode;
  transaction?: (value: MouseEvent) => Promise<void>;
}

interface PanelType {
  subicon: ReactNode;
  color: string;
  title: string;
  value: string;
  disabled: boolean;
  actions: Array<ActionType>;
}

const DAO: FC = () => {
  const { userActiveStake, userClaimableRewards } = useGlobalContext();
  const { isLoading, isEmpty, isError } = {
    isEmpty: userActiveStake.data === '0' && userClaimableRewards.data === '0',
    isLoading:
      userActiveStake.status === 'loading' ||
      userClaimableRewards.status === 'loading',
    isError:
      userActiveStake.status === 'error' ||
      userClaimableRewards.status === 'error'
  };

  const panels: Array<PanelType> = [
    {
      subicon: null,
      color: '#2044F5',
      title: 'Form a new DAO.',
      value: formatAmount({ input: userActiveStake.data || '0' }),
      disabled: false,
      actions: [
        {
          render: <DeployDAO />,
          label: 'Create DAO'
        }
      ]
    },
    {
      subicon: null,
      color: '#2044F5',
      title: 'Setup a profile for your DAO.',
      value: formatAmount({ input: userActiveStake.data || '0' }),
      disabled: false,
      actions: [
        {
          render: <VerifyDAO />,
          label: 'Create Profile'
        }
      ]
    }
  ];

  return (
    <div
      className={`${modifiable(
        'stake',
        [(isLoading || isError || isEmpty) && 'empty'],
        styles
      )} stake`}
    >
      {isLoading || isError || isEmpty ? (
        <div className={styles.wrapper}>No DAOs available.</div>
      ) : (
        panels.map((panel) => (
          <div key={panel.title} className={styles.panel}>
            <div className={styles.title}>{panel.title}</div>

            <div className={styles.actions}>
              {panel.actions.map((action, iteratee) =>
                action.render ? (
                  <div key={action.label}>{action.render}</div>
                ) : (
                  <button
                    key={action.label}
                    type='button'
                    style={{ background: iteratee ? panel.color : '#303234' }}
                    className={modifiable(
                      'action',
                      [panel.disabled && 'disabled'],
                      styles
                    )}
                    onClick={action.transaction}
                  >
                    {action.label}
                  </button>
                )
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default DAO;
