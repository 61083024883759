import { FC, useEffect, useState } from 'react';

import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import {
  AbiRegistry,
  Address,
  AddressValue,
  ContractFunction,
  ResultsParser,
  SmartContract,
  SmartContractAbi
} from '@elrondnetwork/erdjs';
import { ProxyNetworkProvider } from '@elrondnetwork/erdjs-network-providers';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import governanceAbi from 'abi/governance.abi.json';
import { network } from 'config';
import styles from './styles.module.scss';

const networkProvider = new ProxyNetworkProvider(network.gatewayAddress);
const abiRegistry = AbiRegistry.create(governanceAbi);
const abi = new SmartContractAbi(abiRegistry);
const contract = new SmartContract({
  address: new Address(network.governanceContract),
  abi: abi
});

// TODO: dedupe code
const getDaoStatus = async (daoAddress: Address, caller: Address) => {
  const getDAOStatus = contract.createQuery({
    func: new ContractFunction('DAOStatus'),
    args: [new AddressValue(daoAddress)],
    caller: caller
  });
  const daoResponse = await networkProvider.queryContract(getDAOStatus);
  const result: any = new ResultsParser().parseQueryResponse(
    daoResponse,
    contract.getEndpoint('DAOStatus')
  );

  // TODO: handle error
  return result.firstValue || result.returnMessage;
};

const getDaoVerifiedDetails = async (caller: Address, mappedResults: any) => {
  const res = mappedResults.map(async (d: Address) => {
    const status: string = (await getDaoStatus(d, caller))?.name;
    const details: any[] = [];
    if (status.startsWith('DaoVerified')) {
      const getDetails = contract.createQuery({
        func: new ContractFunction('getVerifiedSC'),
        args: [new AddressValue(d)],
        caller: caller
      });

      const resp = await networkProvider.queryContract(getDetails);
      const verified: any = new ResultsParser().parseQueryResponse(
        resp,
        contract.getEndpoint('getVerifiedSC')
      );

      verified.firstValue.fieldsByName.forEach((value: any, key: any) =>
        details.push({
          [key]: value.value?.value?.toString() || value.value?.toString()
        })
      );
    }
    return {
      address: d,
      details: details,
      status: (await getDaoStatus(d, caller))?.name
    };
  });
  return await Promise.all(res);
};

const DAOList: FC = () => {
  const { address } = useGetAccountInfo();
  const [loading, setLoading] = useState<boolean>(true);
  const [daos, setDaos] = useState<
    {
      status: any;
      address: Address;
      details: any;
    }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      const getAllDAOs = contract.createQuery({
        func: new ContractFunction('getAllDAO'),
        args: [],
        caller: new Address(address)
      });
      const daoResponse = await networkProvider.queryContract(getAllDAOs);
      const result: any = new ResultsParser().parseQueryResponse(
        daoResponse,
        contract.getEndpoint('getAllDAO')
      );
      const mappedResults = result.values[0].items.map(
        (item: { value: Address }) => item.value
      );

      const daosWithDetail = await getDaoVerifiedDetails(
        new Address(address),
        mappedResults
      );

      setDaos(daosWithDetail);
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  const handleRedirect = () =>
    Boolean(address) ? setLoading(false) : navigate('/unlock');

  useEffect(handleRedirect, [address]);

  if (loading) {
    return (
      <div
        style={{ fontSize: '30px' }}
        className='d-flex align-items-center justify-content-center text-white flex-fill'
      >
        <FontAwesomeIcon
          icon={faSpinner}
          size='2x'
          spin={true}
          className='mr-3'
        />
        Loading...
      </div>
    );
  }

  return (
    <div className={styles.daoList}>
      <h2>Active DAOs</h2>
      {daos?.map((d) => (
        <>
          <h3>{d.address.bech32()}</h3>
          <h3>Status: {d.status}</h3>
          {d.details?.map((d: any, i: any) => (
            <div key={i} style={{ color: 'white' }}>
              {JSON.stringify(d)}
            </div>
          ))}
        </>
      ))}
    </div>
  );
};

export default DAOList;
